@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

body {
    margin: 0;
    padding:0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','raleway',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* ensure everything fits the width of whatever screen you view it on and adjusts accordingly */
body, html {
    width:100vw;
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white-filter {
    filter: brightness(0) invert(1);
}

h1 {
    font-size: calc(4rem + 2vmin);
    background-repeat: repeat;
    --webkit-background-clip: text;
    color:transparent;
}
.welcome-container {
    z-index: -5;
}
.welcome-image {
    z-index: -10;
    clip-path: polygon(100% 0%, 87% 82%, 100% 100%, 21% 100%, 0 20%, 25% 0%);
}
.light-mode {
    text-shadow: 2px 6px 8px rgba(0, 0, 0, 0.5);
}
.dark-mode {
    text-shadow: 3px 6px 8px rgba(196, 235, 0, 0.5);
}
.acknowledgement {
    font-size: calc(10px + 2vmin);
}
.about-image  {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 16% 100%, 0 39%);}

    .projects-container {

    }